<template>
  <v-dialog
    class="mem-dialog"
    v-model="dialog"
    :max-width="360"
    overlay-color="#000000"
    overlay-opacity="0.54"
  >
    <div class="upsell-dialog">
      <span class="material-icons close-button" @click="$emit('input', false)"
        >close</span
      >
      <!-- image -->
      <div class="dialog-image">
        <img
          v-if="type === 'spartanTab'"
          src="https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/event/engraving.jpg"
          alt="tab"
        />
        <img
          v-else
          src="https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/event/photo_package.jpg"
          alt="photo"
        />
      </div>

      <!-- title -->
      <div class="dialog-title">
        <span v-if="type === 'spartanTab'">SpartanTab</span>
        <span v-else>{{ $t("upsell_modal_photo_package_title") }}</span>
      </div>
      <!-- body -->
      <div class="dialog-body">
        <div class="body-line">
          <i18n
            v-if="type === 'spartanTab'"
            tag="span"
            path="upsell_modal_spartan_tab_text"
          >
            <span>{{ price | currency(country, currency) }}</span>
          </i18n>
          <i18n v-else tag="span" path="upsell_modal_photo_package_text">
            <span>{{ price | currency(country, currency) }}</span>
          </i18n>
        </div>

        <span v-if="type === 'spartanTab'" class="additional-line">{{
          $t("upsell_modal_spartan_tab_additional_text")
        }}</span>

        <span>{{ $t("upsell_modal_default_text") }}</span>
      </div>
      <div class="dialog-buttons">
        <mem-button @click="$emit('input', false)" btn-type="tertiary-light">{{
          $t("later")
        }}</mem-button>
        <mem-button @click="openLink">{{ $t("add") }}</mem-button>
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  props: {
    value: Boolean,
    type: String, // spartanTab, photoPackage
    country: String,
    currency: String,
    price: [String, Number],
    buttonLink: String,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    openLink() {
      window.open(this.buttonLink, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.upsell-dialog {
  background-color: #ffffff;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 20px;
  position: relative;
  color: #000000;
  border-radius: 12px;
  .close-button {
    position: absolute;
    right: 6px;
    top: 8px;
    color: #000000;
    @include cursorPointer;
  }
  .dialog-image {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .dialog-title {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
  }
  .dialog-body {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin-top: 10px;
    max-width: 310px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .body-line {
      margin-bottom: 20px;
      // max-width: 83%;
    }
    .additional-line {
      margin-bottom: 24px;
    }
  }
  .dialog-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 30px;
    button {
      height: 36px;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.48px;
    }
  }
}
</style>